import {ERROR_CODES} from '../errorCodes'
import ErrorBase from './ErrorBase'

export default class Default extends ErrorBase {
  /**
   * @param {String} errorCode
   * @param {Object} message
   */
  constructor(error, message) {
    super(error, message);
    this.errorCode = ERROR_CODES.DEFAULT;
    this.message = {
      en: 'The server encountered an error processing the request.',
      nl: 'De server heeft een fout aangetroffen bij het verwerken van het verzoek.'
    }
  }
}
