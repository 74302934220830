import Token from "@/utils/authenticationManager/tokens/Token";
import AuthDataHandler from "@/utils/storage/AuthDataHandler";

class AccessToken extends Token {
  constructor() {
    super();
  }

  get value() {
    return AuthDataHandler.getAccessToken();
  }

  get expiration() {
    return AuthDataHandler.getAccessTokenExpiration();
  }

  set(value, expiresIn) {
    AuthDataHandler.setAccessToken(value, expiresIn);
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new AccessToken();
    }
    return this.instance;
  }
}

export default AccessToken.getInstance();
