import ValidationRules from '../rules/ValidationRules';

export default class RuleDetail {
  constructor({id, formId, fieldId, fieldName, details, isRequired}) {
    this.id = id;
    this.formId = formId;
    this.fieldId = fieldId;
    this.fieldName = fieldName;
    const detailInfo = details.split(':').map(info => info);
    this.ruleName = detailInfo[0];
    this.ruleLimit = detailInfo[1];
    this.isRequired = isRequired;
  }

  validateRule(formData, addErrorMessage) {
    // get validator, e.g. rules.required
    const ruleValidator = ValidationRules.getValidator(this.ruleName);
    // return true means no validation since the rule is not supported
    if (!ruleValidator) return true;
    if (!this.validateData(formData, ruleValidator)) {
      // if validation fails, add error message
      const message = ValidationRules.getErrorMessage(this.fieldName, this.ruleName, this.ruleLimit);
      // add error message to parent RuleField class
      addErrorMessage(message);
      return false;
    }
    return true;
  }

  validateData(formData, ruleValidator) {
    if (!formData || !this.fieldName) return false;
    /** if data don't exist, return true or false depending on isRequired (to show all errors together) **/
    return !formData[this.fieldName] ? !this.isRequired : ruleValidator.validate(this.fieldName, formData, this.ruleLimit);
  }
}
