import BaseRule from "@/utils/yo-validator/rules/BaseRule";

export default class RuleTelephoneNumber extends BaseRule {
  constructor() {
    super();
  }

  validate(fieldName, formData) {
    const regTelNum = /^((\+31)|(0031)|0)(\(0\)|)(\d{1,3})(\s|-|)(\d{8}|\d{4}\s\d{4}|\d{2}\s\d{2}\s\d{2}\s\d{2})$/g;
    return super.validateByRegex(fieldName, formData, regTelNum);
  }
}
