import BaseForm from "@/utils/yo-validator/forms/BaseForm";
import {MODES} from "@/utils/yo-validator/forms/FormManager";

export default class AggressiveForm extends BaseForm {
  constructor(formId, mode, name, onFormDataUpdate) {
    super(formId, mode, name, onFormDataUpdate);
    this.mode = MODES.AGGRESSIVE;
  }

  setFormData({fieldId, fieldName, data, target}) {
    super.setFormData({fieldId, fieldName, data, target});
    // first set the field data
    this.fields[fieldId].setFieldData({formData: this.formData});
    // validate immediately after that
    super.validateField(fieldId);
  }
}