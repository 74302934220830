import ErrorHandler from '../ErrorHandler';

export default class ErrorBase {
  constructor(error, message) {
    this.code = error;
    this.message = message;
  }

  getMessage() {
    return this.message[ErrorHandler.selectedLanguage];
  }
}