import Token from "@/utils/authenticationManager/tokens/Token";
import AuthDataHandler from "@/utils/storage/AuthDataHandler";

class RefreshToken extends Token {
  constructor() {
    super();
  }

  get value() {
    return AuthDataHandler.getRefreshToken();
  }

  set(value, expiresIn) {
    AuthDataHandler.setRefreshToken(value, expiresIn);
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new RefreshToken();
    }
    return this.instance;
  }
}

export default RefreshToken.getInstance();
