import EventManager from "@/utils/yo-validator/EventManager";
import LazyRuleFieldProgress from "@/utils/yo-validator/fields/LazyRuleFieldProgress";
import BaseRuleField from "@/utils/yo-validator/fields/BaseRuleField";
import AggressiveRuleField from "@/utils/yo-validator/fields/AggressiveRuleField";

const EVENT_INVALID_FIELD = 'INVALID_FIELD';

export const MODES = Object.freeze({
  SUBMIT: 1,
  LAZY: 2,
  AGGRESSIVE: 3
});

class FieldManager {
  constructor() {
    this.fields = {};
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new FieldManager();
    }
    return this.instance;
  }

  setFieldRules({fieldId, fieldName, rules, type, mode, callback}) {
    if (mode === MODES.LAZY) {
      this.fields[fieldId] = new LazyRuleFieldProgress({id: fieldId, fieldName, rules, type});
    } else if (mode === MODES.AGGRESSIVE) {
      this.fields[fieldId] = new AggressiveRuleField({id: fieldId, fieldName, rules, type});
    } else {
      this.fields[fieldId] = new BaseRuleField({id: fieldId, fieldName, rules, type});
    }
    // subscribe the invalid event
    EventManager.subscribe(EVENT_INVALID_FIELD, callback);
    return {field: this.fields[fieldId]};
  }

  setFieldDataById({fieldId, fieldName, data, target}) {
    const ruleField = this.fields[fieldId];
    ruleField.setFieldDataByTargetData({parent: this, target, data: {[fieldName]: data}});
  }

  validateFieldById(fieldId) {
    this.fields[fieldId].validateRules();
  }

  validateFieldByData(fieldId, dataValue) {
    const ruleField = this.fields[fieldId];
    const parsedData = {[ruleField.fieldName]: dataValue};
    this.fields[fieldId].validateRulesByData(parsedData);
  }
}

export default FieldManager.getInstance();