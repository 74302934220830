import BaseRule from "@/utils/yo-validator/rules/BaseRule";

export default class RulePasswordStrength extends BaseRule {
  constructor() {
    super();
  }

  validate(fieldName, formData) {
    if (!formData || !fieldName) return false;
    // deal with the case of confirm
    // if (limit && limit.charAt(0) === CHAR_CONFIRM) {
    //   return formData[fieldName] === formData[limit.substring(1)];
    // }
    // check regular password
    return this.isPasswordStrong(formData[fieldName]);
  }

  isPasswordStrong(password) {
    let strength = 0;
    // if (password.match(/[a-z]+/)) {
    //   strength += 1;
    // }
    // if (password.match(/[A-Z]+/)) {
    //   strength += 1;
    // }
    // if (password.match(/[0-9]+/)) {
    //   strength += 1;
    // }
    if (password.length >= 8) {
      strength += 1;
    }
    return strength === 1;
  }
}
