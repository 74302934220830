import AccessToken from "@/utils/authenticationManager/tokens/AccessToken";

class ApiHeader {
  constructor() {}

  get authHeader() {
    // Check if access token is present
    if (!AccessToken.value) {
      return null;
    }
    
    return {
      Authorization: `Bearer ${AccessToken.value}`,
      'accept-language': 'en'
    }
  }
}

export default new ApiHeader();