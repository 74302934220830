import BaseRule from "@/utils/yo-validator/rules/BaseRule";

export default class RulePositiveNumeric extends BaseRule {
  constructor() {
    super();
  }

  validate(fieldName, formData) {
    const regNumeric = /^\d*[1-9]\d*$/;
    return super.validateByRegex(fieldName, formData, regNumeric);
  }
}
