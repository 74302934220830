import BaseRule from "@/utils/yo-validator/rules/BaseRule";

export default class RuleMin extends BaseRule {
  constructor() {
    super();
  }

  validate(fieldName, formData, minValue) {
    if (minValue === undefined || minValue === null) return true;
    const fieldData = formData[fieldName];
    return parseFloat(fieldData) >= parseFloat(minValue);
  }

  parseMessage(message, ruleName, ruleLimit) {
    return super.parseStandardMessage(message, ruleName, ruleLimit)
  }
}