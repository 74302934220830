import SubmitForm from "@/utils/yo-validator/forms/SubmitForm";
import LazyForm from "@/utils/yo-validator/forms/LazyForm";
import AggressiveForm from "@/utils/yo-validator/forms/AggressiveForm";
import EventManager from "@/utils/yo-validator/EventManager";
import IdGenerator from "@/utils/yo-validator/IdGenerator";

const EVENT_INVALID_FIELD = 'INVALID_FIELD';

export const MODES = Object.freeze({
  SUBMIT: 1,
  LAZY: 2,
  AGGRESSIVE: 3
});

class FormManager {
  constructor() {
    this.formPackages = {};
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new FormManager();
    }
    return this.instance;
  }

  validateFormFields(formId) {
    // it must exists since there is no human intervention
    return this.formPackages[formId].validateFormFields();
  }

  validateFieldByData(formId, fieldId, dataValue) {
    this.formPackages[formId].validateFieldByData(fieldId, dataValue);
  }

  validateFieldById(formId, fieldId) {
    this.formPackages[formId].validateField(fieldId);
  }

  getFormModeByFormId(formId) {
    return this.formPackages[formId].mode;
  }

  setFieldRules({fieldId, fieldName, rules, type, callback}) {
    // rendering order: FormWrapper first, then all fields inside, so always the last element
    const keyList = Object.keys(this.formPackages);
    if (keyList.length > 0) {
      // last element is the current form
      const current = this.formPackages[keyList[keyList.length - 1]];
      const {field} = current.addRuleField({fieldId, formId: current.id, fieldName, rules, type})
      // subscribe the invalid event
      EventManager.subscribe(EVENT_INVALID_FIELD, callback);
      return {formId: current.id, field};
    }
  }

  setFormDataByFormId({formId, fieldId, fieldName, data, target}) {
    this.formPackages[formId].setFormData({fieldId, fieldName, data, target});
  }

  addFormByMode({mode, name, onFormDataUpdate}) {
    // get unique id
    const formId = IdGenerator.getId();
    let form;
    if (mode === MODES.SUBMIT) {
      form = new SubmitForm(formId, mode, name, onFormDataUpdate);
    } else if (mode === MODES.LAZY) {
      form = new LazyForm(formId, mode, name, onFormDataUpdate);
    } else {
      form = new AggressiveForm(formId, mode, name, onFormDataUpdate)
    }
    // assign a new form
    this.formPackages = Object.assign(this.formPackages, {[formId]: form});
    return form;
  }
}

export default FormManager.getInstance();
