export default {
  names: {
    username: 'username',
    email: 'email',
    password: 'password',
    numberField: 'number',
    profile: 'profile image',
    firstName: 'first name',
    lastName: 'last name',
  },
  messages: {
    required: {
      default: 'The {fieldName} is required.',
      password: 'Please enter a password.'
    },
    alpha: {
      default: 'The {fieldName} can only contain alphabets.'
    },
    alphaNumeric: {
      default: 'The {fieldName} can only contain alphabets and numbers.'
    },
    numeric: {
      default: 'The {fieldName} can only contain numbers.'
    },
    email: {
      default: 'Invalid email address.',
    },
    ext: {
      default: 'The extension of your {fieldName} can only be {ext}.',
      profile: 'You have upload files of correct types - {ext}.'
    },
    max: {
      default: 'The {fieldName} cannot be bigger than {max}.'
    },
    min: {
      default: 'The {fieldName} cannot be smaller than {min}.',
      password: 'The password must be at least {min} characters.'
    },
    maxLength: {
      default: 'The {fieldName} cannot be longer than {maxLength} characters.'
    },
    minLength: {
      default: 'The {fieldName} cannot be shorter than {minLength} characters.'
    },
    maxSize: {
      default: 'The {fieldName} cannot be larger than {maxSize}MB.'
    },
    minSize: {
      default: 'The {fieldName} cannot be smaller than {minSize}MB.'
    },
    passwordConfirm: {
      default: 'The passwords do not match.'
    },
    passwordStrength: {
      default: 'Passwords must be at least 8 characters long.'
      // default: 'Passwords must be between 6 and 20 characters long and contain at least one uppercase letter, one lowercase letter and one number.'
    }
  }
}
